@import '../../scss/base.module';

.base-button {
  text-align: center;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;
  transition: all 0.1s ease-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & > * + * {
    margin-left: $spacing-xs;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }

  &__text {
    @include font-bold;
  }

  &__trailing-icon {
    display: flex;
  }

  &--rounded-corners {
    border-radius: 200px;
  }

  &--drop-shadow {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.18); //this should be eventually the $shadow color.
  }

  &--circular-button {
    padding: 10px 10px;
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }

  /***************
  * Button sizes *
  ***************/

  &--size-small {
    padding: 8px 14px;
    font-size: $font-s;
    line-height: $line-height-xs;
  }

  &--size-medium {
    padding: 14px 20px;
    font-size: $font-s;
    line-height: $line-height-xs;
  }

  &--size-large {
    padding: 18px 30px;
  }

  &--full-width {
    width: 100%;
  }

  /***************
  * Button colors *
  ***************/
  &--color-primary {
    border: 0;
    color: $white;
    background: $primary;

    &:hover:not(:disabled) {
      background: $primary-light;
      color: $white;
    }
  }

  &--color-secondary {
    border: 0;
    color: $secondary-blue;
    background: $interaction;

    &:hover:not(:disabled) {
      background: $interaction-light;
      color: $secondary-blue;
    }
  }

  &--color-tertiary {
    color: $interaction-dark;
    background: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: $medium;

    &:hover:not(:disabled) {
      border-color: $interaction-dark;
    }
  }

  &--color-tertiary-light {
    color: $white;
    background: transparent;
    border-width: 1px;
    border-color: $white;
    border-style: solid;
    &:hover:not(:disabled) {
      border-color: $white;
      color: $white;
    }
  }

  &--color-link {
    background: transparent;
    color: $interaction-dark;
    text-align: left;
    padding: 0;
    border-color: transparent;
    text-decoration: underline;
    @include font-light;
    @include font-s;

    &:hover {
      color: $interaction-dark;

      &:not(:disabled) {
        color: $interaction-dark;
      }
    }
    &:disabled {
      color: $light;
    }

    &:active {
      color: $interaction-dark;
    }
  }

  &--color-tab-link {
    background: transparent;
    color: $medium;
    border-color: transparent;
    border-bottom: 4px solid $light;
    @include font-bold;
    @include font-s;
  
    &:hover {
      color: $primary-dark;
  
      &:not(:disabled) {
        color: $primary-dark;
      }
    }
  
    &:active {
      border-bottom: 4px solid transparent;
    }
  }
}